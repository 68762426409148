import { Module } from 'vuex';
import { Session } from '/@/utils/storage.ts';
import { oauthToken } from "/@/api/login/index";
// 此处加上 `.ts` 后缀报错，具体原因不详
import { AccessToken, RootStateTypes } from '/@/store/interface/index';
import { getPlatformAliasData } from '/@/utils/platformUtil';
import { loadAgentArrData } from '/@/utils/agentUtil';

// ~~~~~~~~~~~~~~~~ hang ~~~~~~~~~~~~~~
const accessTokenModule: Module<AccessToken, RootStateTypes> = {
    namespaced: true,
    state: {
        token: '',
        refreshToken: '',
    },
    mutations: {
        // 设置token
        setToken(state: any, data: object) {
            state.token = data;
            Session.set('token', data);
        },
        // 设置token
        setRefreshToken(state: any, data: object) {
            state.refreshToken = data;
            Session.set('refreshToken', data);
        },
    },
    actions: {
        // 用户登陆
        login({ commit }, loginInfo) {
            const { username, password, grant_type, validateCode, uuid } = loginInfo;
            return new Promise((resolve, reject) => {
                oauthToken({
                    username,
                    password,
                    grant_type,
                    validateCode,
                    uuid
                }).then(response => {
                    console.log(response);
                    const { access_token, refresh_token, token_type } = response as any;
                    const token = token_type + " " + access_token;
                    commit('setToken', token);
                    commit('setRefreshToken', refresh_token);
                    resolve(username);

                    getPlatformAliasData();
                    loadAgentArrData();
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 刷新token
        refreshToken({ commit }, refreshToken) {
            commit('setToken', undefined);
            return new Promise((resolve, reject) => {
                oauthToken({
                    grant_type: 'refresh_token',
                    refresh_token: refreshToken,
                }).then(response => {
                    const { access_token, refresh_token, token_type } = response as any;
                    const token = token_type + " " + access_token
                    commit('setToken', token);
                    commit('setRefreshToken', refresh_token);
                    resolve(token)

                    getPlatformAliasData();
                    loadAgentArrData();
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
};

export default accessTokenModule;
