import request from '/@/utils/request';
import qs from "qs";


export function refreshCache() {
    return request({
        url: '/admin/refreshCache',
        method: 'POST',
    });
}
export function sendPushMsg(params: object) {
    return request({
        url: '/admin/sendPushMsg',
        method: 'POST',
        data: qs.parse(params)
    });
}
// export function getReviewVersion(params?: object) {
//     return request({
//         url: '/admin/getReviewVersion',
//         method: 'GET',
//         params
//     });
// }
// export function addReviewVersion(params: object) {
//     return request({
//         url: '/admin/addReviewVersion',
//         method: 'PUT',
//         params
//     });
// }
export function getReviewIpArr(params?: object) {
    return request({
        url: '/admin/getReviewIpArr',
        method: 'GET',
        params
    });
}
export function deleteReviewIp(params?: object) {
    return request({
        url: '/admin/deleteReviewIp',
        method: 'DELETE',
        params
    });
}
export function getRedisReviewIpArr(params?: object) {
    return request({
        url: '/admin/getRedisReviewIpArr',
        method: 'GET',
        params
    });
}
export function deleteRedisReviewIp(params?: object) {
    return request({
        url: '/admin/deleteRedisReviewIp',
        method: 'DELETE',
        params
    });
}
export function getReviewUser(params?: object) {
    return request({
        url: '/admin/getReviewUser',
        method: 'GET',
        params
    });
}
export function isReexamine(params?: object) {
    return request({
        url: '/admin/isReexamine',
        method: 'GET',
        params
    });
}
export function isReviewLongterm(params?: object) {
    return request({
        url: '/admin/isReviewLongterm',
        method: 'GET',
        params
    });
}
export function isReexamineAll(params?: object) {
    return request({
        url: '/admin/isReexamineAll',
        method: 'GET',
        // params
    });
}
export function isReviewLongtermAll(params?: object) {
    return request({
        url: '/admin/isReviewLongtermAll',
        method: 'GET',
        // params
    });
}
export function cleanReexamine(params?: object) {
    return request({
        url: '/admin/cleanReexamine',
        method: 'DELETE',
        params
    });
}

export function isReviewIp(params?: object) {
    return request({
        url: '/admin/isReviewIp',
        method: 'GET',
        params
    });
}
export function cleanReviewIp(params?: object) {
    return request({
        url: '/admin/cleanReviewIp',
        method: 'PUT',
        params
    });
}
export function changeReviewLongterm(params?: object) {
    return request({
        url: '/admin/changeReviewLongterm',
        method: 'PUT',
        params
    });
}
export function isReviewRegisterToAAll(params?: object) {
    return request({
        url: '/admin/isReviewRegisterToAAll',
        method: 'GET',
        // params
    });
}
export function changeReviewRegisterToA(params?: object) {
    return request({
        url: '/admin/changeReviewRegisterToA',
        method: 'PUT',
        params
    });
}
export function changeReviewState0(params?: object) {
    return request({
        url: '/admin/changeReviewState2',
        method: 'PUT',
        params
    });
}
export function addReviewUser(params: object) {
    return request({
        url: '/admin/addReviewUser',
        method: 'PUT',
        params
    });
}
export function delReviewUser(params: object) {
    return request({
        url: '/admin/delReviewUser',
        method: 'DELETE',
        params
    });
}


export function getConfigJson(params?: object) {
    return request({
        url: '/admin/getConfigJson',
        method: 'GET',
        params
    });
}
export function changeConfigJson(params: object) {
    return request({
        url: '/admin/changeConfigJson',
        method: 'PUT',
        data: qs.parse(params)
    });
}


export function getPlatformAlias(params?: object) {
    return request({
        url: '/admin/getPlatformAlias',
        method: 'GET',
        params
    });
}
export function setPlatformAlias(params: object) {
    return request({
        url: '/admin/setPlatformAlias',
        method: 'PUT',
        params
    });
}

export function getPlatformIgnoreCalifornia(params?: object) {
    return request({
        url: '/admin/getPlatformIgnoreCalifornia',
        method: 'GET',
        params
    });
}

export function getPlatformConfig(params?: object) {
    return request({
        url: '/admin/getPlatformConfig',
        method: 'GET',
        params
    });
}
export function setPlatformIgnoreCalifornia(params: object) {
    return request({
        url: '/admin/setPlatformIgnoreCalifornia',
        method: 'PUT',
        params
    });
}
export function getPlatformTimeout() {
    return request({
        url: '/admin/getPlatformTimeout',
        method: 'GET',
    });
}


export function setPlatformTimeout(params?: object) {
    return request({
        url: '/admin/setPlatformTimeout',
        method: 'PUT',
        params
    });
}